<template>
  <div class="collections center-wrapper">
    <h3>OUR COLLECTIONS</h3>
    <div class="tiles-wrapper">
      <CollectionTile :item="item" v-for="item of categoriesList" :key="item.id"></CollectionTile>
    </div>
  </div>
</template>
<script>
import CollectionTile from '@/components/atoms/CollectionTile.vue'
import { mapState } from 'vuex'
export default {
  components: {
    CollectionTile
  },
  data() {
    return {
      items: [
        {
          id: 1,
          icon: 'chair.svg',
          label: 'ArmChairs'
        },
        {
          id: 2,
          icon: 'flower.svg',
          label: 'Bath Room'
        },
        {
          id: 3,
          icon: 'dining-chair.svg',
          label: 'Dining Chairs'
        },
        {
          id: 4,
          icon: 'sofa.svg',
          label: 'Sofas'
        },
        {
          id: 5,
          icon: 'dining-chair.svg',
          label: 'Dining Chairs'
        },
        {
          id: 6,
          icon: 'dining-table.svg',
          label: 'Dining Table'
        },
        {
          id: 7,
          icon: 'sofa.svg',
          label: 'Sofas'
        },
        {
          id: 8,
          icon: 'flower.svg',
          label: 'Bath Room'
        },
        {
          id: 9,
          icon: 'chair.svg',
          label: 'ArmChairs'
        },
        {
          id: 10,
          icon: 'dining-table.svg',
          label: 'Dining Table'
        }
      ]
    }
  },
  computed: {
    ...mapState('products', ['categoriesList'])
  },
  mounted() {
    this.$store.dispatch('products/fetchCategories')
  }
}
</script>
<style lang="sass" scoped>
.collections
  padding: 8rem 0
  h3
    @include font-source('Poppins', 20px, 400, $black1)
.tiles-wrapper
	display: flex
	justify-content: center
	flex-wrap: wrap
</style>
