<template>
  <div class="list-wrapper">
    <ul>
      <li v-for="item in filteredList.splice(0, 10)" :key="item.id">
        <router-link :to="{ name: 'ProductDetails', params: { id: item.id } }" @click.native="$emit('close')">
          {{ item.Name }}
        </router-link>
      </li>
      <li v-if="value&&!filteredList.length">
        No results found!
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['isLoader']),
    ...mapState('products', ['productList']),
    filteredList() {
      let list = []
      if (this.value) {
        list = this.productList.filter((item) => item.Name.toLowerCase().includes(this.value.toLowerCase()))
      }
      return list
    }
  }
}
</script>

<style lang="sass" scoped>
.list-wrapper
  ul
    background: #fff
    box-shadow: 0 0 10px black
    z-index: 2
    position: relative
    li
      padding: 5px
      border-bottom: 1px solid $grey-2
      a
        text-decoration: none
        color: $primary-text
    li
      transition: all .2s
      &:hover
        border-left: 3px solid $orange
        border-right: 3px solid $orange
</style>