<template>
  <div class="section-wrap">
    <div class="tile-wrapper center-wrapper">
      <div class="tile" v-for="item of items" :key="item.id">
        <div class="tile-wrap">
          <div class="tile__flag-sec"><img :src="require('@/assets/icons/' + item.icon)" /></div>
          <div class="tile__label">{{ item.label }}</div>
          <div class="tile__title">{{ item.title }}</div>
          <p class="tile__desc">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          label: 'Minhas Furniture',
          title: 'Made in Canada',
          description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus reiciendis dignissimos unde impedit nemo expedita facilis. Ad repellat, hic inventore soluta enim saepe architecto voluptatum minima dignissimos. Magni, aspernatur maxime!',
          icon: 'canada-flag.svg'
        },
        {
          id: 2,
          label: 'Minhas Furniture',
          title: 'Made in Asia',
          description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus reiciendis dignissimos unde impedit nemo expedita facilis. Ad repellat, hic inventore soluta enim saepe architecto voluptatum minima dignissimos. Magni, aspernatur maxime!',
          icon: 'asia-flag.svg'
        },
        {
          id: 3,
          label: 'Minhas Furniture',
          title: 'Made in USA',
          description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus reiciendis dignissimos unde impedit nemo expedita facilis. Ad repellat, hic inventore soluta enim saepe architecto voluptatum minima dignissimos. Magni, aspernatur maxime!',
          icon: 'usa-flag.svg'
        }
      ]
    }
  }
}
</script>
<style lang="sass" scoped>
.section-wrap
  width: 100%
  background-color: $grey
.tile-wrapper
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding: 8em 0 6em 0
.tile
    // width: calc(100% /3 - 1.5em)
    position: relative
    background: transparent
    @media(max-width: $lg-breakpoint) and (min-width: $ms-breakpoint)
      position: unset
    @media(max-width: $lg-breakpoint)
      width: calc(100% /2 - 1.5em)
      margin-bottom: 5em
    @media(max-width: $md-breakpoint)
      width: 100%
    .tile-wrap
      display: flex
      flex-direction: column
      background: $white
      max-width: 20rem
      height: 26em
      padding: 35px 20px
      @media(max-width: $lg-breakpoint) and (min-width: $ms-breakpoint)
        position: relative
      @media(max-width: $md-breakpoint)
        max-width: 95%
        height: auto
    &__label
        text-align: left
        margin-top: 1rem
        text-transform: uppercase
        @include font-source('Poppins', 13px, 600, $black1)
    &__title
        margin: 1rem
        line-height: 1.2
        @include font-source('Poppins', 28px, 500, $black1)
    &__desc
        text-align: justify
        line-height: 25px
        letter-spacing: 1px
        @include font-source('Poppins', 13px, 400, $text-grey)
    &__flag-sec
        position: absolute
        top: -70px
        height: 7rem
        width: 7rem
        left: 0 
        right: 0
        margin-left: auto 
        margin-right: auto
        background-color: $white
        z-index: 10
        align-self: flex-end
        display: flex
        align-items: center
        justify-content: center
        filter: drop-shadow(-9.786px 16.286px 6.5px rgba(0,0,0,0.03))
        @media(max-width: $lg-breakpoint) and (min-width: $ms-breakpoint)
          right: -25px
        img
            height: 6rem
            width: 6rem
</style>
