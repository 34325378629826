<template>
  <div class="home">
    <Banner :isHome="true"></Banner>
    <CountriesSection></CountriesSection>
    <CollectionSection></CollectionSection>
  </div>
</template>
<script type="text/javascript" src="//analytics.aweber.com/js/awt_analytics.js?id=1_OVo"></script>
<script>
// @ is an alias to /src
import Banner from '@/components/molecules/Banner.vue'
import CountriesSection from '@/components/molecules/CountriesSection.vue'
import CollectionSection from '@/components/templates/CollectionSection.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    CountriesSection,
    CollectionSection
  },
  metaInfo() {
    return {
      title: 'Minhas Furniture House Ltd.'
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
