<template>
  <div class="header center-wrapper">
    <div class="header__logo-section">
      <div>
        <img src="@/assets/icons/search.svg" @click="isSearch=true" v-if="!isSearch"/>
        <div class="search" v-else>
          <div :class="{'active': isFocus}" class="search-input">
            <input v-model="searchQuery" @input="debounceSearch" @focus="isFocus=true" @blur="isFocus=false" placeholder="Search product..." />
            <img src="@/assets/icons/close_icon.png" @click="onClose" />
          </div>
          <List @close="onClose" v-if="isSearchActive" :value="searchQuery" class="list"/>
        </div>
      </div>
      <img src="@/assets/icons/logo.svg" />
      <div class="header__right-section">
        <router-link :to="{ name: 'Profile', query: { isMenu: true } }">
          <img class="header__right-icon pointer" src="@/assets/icons/profile-icon.svg" />
        </router-link>
       <!-- <router-link :to="{ name: 'Wishlist' }">
          <img class="header__right-icon pointer" src="@/assets/icons/star.svg" />
        </router-link>-->
        <router-link :to="{ name: 'Cart' }" v-if="userType !== salesRep">
          <img class="header__right-icon pointer" src="@/assets/icons/cart.svg" />
        </router-link>
        <span v-if="cartItemCount && userType !== salesRep" class='badge badge-warning' id='lblCartCount'> {{ getCartItemCount }}</span>
      </div>
    </div>
    <div class="header__content">
      <ul class="header__menu">
        <li>
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'About' }">About Us</router-link>
        </li>
        <li>
          <router-link :to="{ name : 'Products' }">Products</router-link>
        </li>
        <li>
          <router-link to="/shop">where to buy</router-link>
        </li>
        <!-- <router-link tag="li" to="/products">Our Story</router-link> -->
        <li class="dropdown-wrapper" >
          <router-link to="/contact-us"
            >Contacts
            <ul class="drop-down">
              <li>Join our Team</li>
              <li>Reach Us</li>
            </ul>
          </router-link>
        </li>
        <li class="dropdown-wrapper" v-if="getUserName">
         <span class="user">welcome '{{ getUserName }}'</span>
         <ul class="drop-down">
            <li @click="logout">Logout</li>
          </ul>
        </li>
        <li class="bold" @click="$emit('login')" v-else>Retailer Login</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import List from '@/components/molecules/List.vue'
export default {
  data() {
    return {
      isSearch: false,
      isSearchActive: false,
      isFocus: false,
      searchQuery: '',
      debounce: null
    }
  },
  computed: {
    ...mapState('auth', ['user', 'userType', 'salesRep']),
    ...mapState('products', ['cartItemCount']),
    getUserName() {
      if (this.user && this.user.fullname) {
        return this.user.fullname.length > 15 ? this.user.fullname.substring(0,15) + ".." :this.user.fullname
      } else if (window.localStorage.getItem('userName')) {
        return window.localStorage.getItem('userName').length > 15 ? window.localStorage.getItem('userName').substring(0,15) + ".." : window.localStorage.getItem('userName')
      } else {
        return false
      } 
    },
    getCartItemCount() {
      if(this.cartItemCount != null){
        return this.cartItemCount;
      }else
      {
        return ""
      }
    }
  },
  components: {
    List
  },
  watch: {
    searchQuery() {
      if (this.isSearchActive) {
        if (!this.searchQuery) {
          this.isSearchActive = false
        }
      }
    }
  },
  created () {
    if (this.user && this.user.fullname) {
      this.$store.dispatch('products/getCartList', 0)
    }
  },
  mounted() {
    this.$store.dispatch('auth/getUserDetails')
    this.$store.dispatch('products/fetchProducts')
  },
  methods: {
    logout() {
      window.localStorage.clear()
      this.$store.commit('auth/setUser', null)
      this.$router.push('/').catch(error => {
        console.log('duplicate')
      })
      location.reload()
    },
    onClose() {
      this.isSearch = false
      this.searchQuery = ''
    },
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.isSearchActive = true
      }, 600)
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  padding-top: 2rem
  &__logo-section
    display: flex
    align-items: center
    justify-content: space-between
    img
      cursor: pointer
  &__right-section
    a
      &.router-link-exact-active
        img
          background: $orange
  &__right-icon
    padding: 0 2px
  &__content
    width: 70%
    margin: 0 auto
  &__menu
    list-style: none
    display: flex
    justify-content: space-around
    padding: 2rem 0 1.5em 0
    @media( max-width: $ms-breakpoint)
      flex-direction: column
    li
      cursor: pointer
      transition: all .2s
      @include font-source('Poppins', 13px, 600, $primary-text)
      letter-spacing: 1px
      text-transform: uppercase
      &:hover
        z-index: 10
        transform: scale(1.2)
      a
        text-decoration: none
        color: inherit
        &.router-link-exact-active
          color: $orange
  .badge 
    padding-left: 9px 
    padding-right: 9px 
    -webkit-border-radius: 9px 
    -moz-border-radius: 9px 
    border-radius: 9px 
    .label-warning[href],
    .badge-warning[href] 
    background-color: #c67605 
  #lblCartCount 
    font-size: 12px 
    background: #ff0000 
    color: #fff 
    padding: 0 5px 
    vertical-align: top 
    margin-left: -10px
.bold
  font-weight: bold
.user
  color: $orange
  text-transform: uppercase
.dropdown-wrapper
  position: relative
  &:hover
    .drop-down
      visibility: visible
.drop-down
  flex-direction: column
  position: absolute
  background-color: #fff
  top: 24px
  min-width: 9rem
  left: -20px
  z-index: 50
  box-shadow: 2px 3px 10px 1px
  border-radius: 2px
  visibility: hidden
  list-style: none
  margin: 0
  padding-left: 0
  padding: 12px
  li
    margin: 5px 0
.search
  position: relative
  .list
    position: absolute
    left: 0
    right: 0
.search-input
  border-bottom: 2px solid $grey-2
  display: flex
  padding: 5px 10px
  margin-bottom: 10px
  &.active
    border-bottom: 2px solid $orange
  input
    border: 0
    width: 100%
    &:active, &:focus
      outline: none
</style>
