import axios from 'axios'
import router from '@/router'
const baseurl =  process.env.VUE_APP_API

const client: any = {
  auth: false,
  withAuth () {
    client.auth = true
    return client
  },
  withoutAuth () {
    client.auth = false
    return client
  }
}

const types = ['get', 'post', 'put', 'delete']
types.forEach((verb) => {
  return new Promise((resolve, reject) => {
    client[verb] = (url: URL, params = {}, data = {}, headers = {}) => {
      return axios({
        method:<any> verb,
        url: `${baseurl}${url}`,
        params,
        data,
        headers: client.auth ? Object.assign({}, { 'Authorization': `Bearer ${window.localStorage.getItem('token')}` }, headers) : headers
      }).then((res:any) => {
        return Promise.resolve(res)
      }).catch((error:any) => {
        return Promise.reject(error)
      })
    }
  })
})

export default client
