<template>
  <router-link :to="{ name: 'Products', query: { 'category.id': item.id }}" :class="{'no-img': !item.Image}" class="collection-tile">
    <!-- <img :src="require(`@/assets/icons/${item.icon}`)" name="icon" /> -->
    <img v-if="item.Image && item.Image.name" :src="getMediaURL(item.Image, 'small')" name="icon" />
    <img src="@/assets/images/no-pictures.png" v-else class="no-picture">
    <label for="icon" v-if="item.name">{{ item.name }}</label>
  </router-link>
</template>
<script>
import getMediaURL from '@/mixins/getMediaURL'
export default {
  mixins: [getMediaURL],
  props: {
    item: {
      type: Object
    }
  }
}
</script>
<style lang="sass" scoped>
.collection-tile
  display: flex
  flex-direction: column
  cursor: pointer
  text-decoration: none
  &.no-img
    width: 17rem
    height: 17rem
    padding: 2rem
    transition: all .2s
  &:hover
    img
      transform: scale(1.2)
  img
    width: 17rem
    height: 17rem
    padding: 2rem
    transition: all .2s
    &.no-picture
      width: unset
      height: unset
  label
    @include font-source('Poppins', 17px, 600, $black1)
</style>
