import client from "./client"

class products {
  fetchProducts (query: any) {
    if (query && Object.keys(query).length === 0) {
      return client.withoutAuth().get("/products/categories", query)
    }else
    {
      return client.withoutAuth().get("/products", query)
    }
  }
  fetchCategories (query: any) {
    return client.withoutAuth().get("/categories", query)
  }
  fetchSubCategories (query: any) {
    return client.withoutAuth().get("/sub-categories", query)
  }
  fetchCollections (query: any) {
    return client.withoutAuth().get("/collections", query)
  }
  fetchWareHouses (query: any) {
    return client.withoutAuth().get("/warehouses", query)
  }
  fetchProductDetails (id: any) {
    return client.withoutAuth().get(`/products/${id}`)
  }
  addWishList (data: any) {
    return client.withAuth().post('/wishlists', null, data)
  }
  removeWishList (id: any) {
    return client.withAuth().delete(`/wishlists/${id}`)
  }
  addToCart (data: any) {
    return client.withAuth().post('/carts', null, data)
  }
  removeCart (id: any) {
    return client.withAuth().delete(`/carts/${id}`)
  }
  getCartList (id: any) {
    return client.withAuth().get('/carts', null, id)
  }
  userCartListExists (id: any) {
    return client.withAuth().get('/carts')
  }
  updateToCart (data: any) {
    return client.withAuth().put('/carts/me', null, data)
  }
  update (data: any, id:any ) {
    return client.withAuth().put(`/carts/${id}`, null, data)
  }
  sendEmail(data: any) {
    return client.withAuth().post("/send-emails", null, data)
  }
}

export default new products()