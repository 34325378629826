import Vue from "vue";
import Vuex from "vuex";
import auth from './modules/auth'
import files from './modules/files'
import products from './modules/products'
import contact from './modules/contact'
import order from './modules/order'
import salesrep from './modules/salesrep'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoader: false,
    errorStatus: 'status is',
    message: null
  },
  mutations: {
    setLoader (state, value) {
      state.isLoader = value
    },
    setMessage (state, payload) {
      state.message = payload
    },
    setErrorStatus(state, data) {
      state.errorStatus = data
    }
  },
  actions: {},
  modules: {
    auth,
    files,
    products,
    contact,
    order,
    salesrep
  }
});
