<template>
  <div>
    <Message v-if="message" :type="message.type" :message="message.message" />
    <div v-if="!message" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-heading">
              <img src="@/assets/icons/signin.png" />
              <p>RETAILER LOGIN</p>
            </div>
            <span @click="$emit('close')" class="pointer close">
              <img src="@/assets/icons/close_icon.png" />
            </span>
          </div>
          <div class="modal-body">
            <Loader class="loader" v-if="isLoader"/>
            <div class="center-wrapper">
            <!-- For Login -->
            <form @submit="submit" v-if="isLogin" class="input-wrapper">
              <div class="input-container">
                <span><img src="@/assets/icons/user_icon.png" /></span>
                <input class="input-field" type="text" placeholder="Your email address" v-model="loginForm.identifier">
                <p v-if="errors.hasOwnProperty('identifier')" class="error">Field required</p>
                <p v-if="errors.hasOwnProperty('identifierRegex')" class="error">Email not found!</p>
              </div>
              <div class="input-container">
                <span><img src="@/assets/icons/password.png" /></span>
                <input class="input-field" type="password" placeholder="Password" v-model="loginForm.password" @keyup.enter="onLogin">
                <p v-if="errors.hasOwnProperty('password')" class="error">Field required</p>
              </div>
              <div class="outer-box">
                <div class="inner-box pointer">
                  <input type="radio" id="remember" class="pointer">
                  <label for="remember" class="pointer">Remember me</label><br>
                </div>
                <div class="inner-box pointer">
                  <div @click="passwordLost">Lost your password?</div>
                </div>
              </div>
              <div class="input-wrapper-button">
                <button class="black pointer" @click="onLogin">LOGIN</button>
                <button class="outline pointer" @click="registerAccount">CREATE AN ACCOUNT</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/components/molecules/Message'
import { mapState } from 'vuex'
import Loader from '@/components/molecules/Loader'
export default {
  data () {
    return {
      isLogin: true,
      errors: {},
      loginForm: {
        identifier: '',
        password: ''
      },
      emailRegex: /\S+@\S+\.\S+/
    }
  },
  components: {
    Loader,
    Message
  },
  watch: {
    user: function () {
      if (this.user &&  this.user.fullname) {
        this.$emit('close')
      }
    }
  },
  computed: {
    ...mapState(['isLoader', 'message']),
    ...mapState('auth', ['user'])
  },
  methods: {
    submit (e) {
      e.preventDefault()
    },
    passwordLost () {
      this.$emit('close')
      this.$router.push({name: 'resetPassword'})
    },
    validateFields () {
      this.errors = {}
      if (!this.loginForm['identifier']) {
        this.$set(this.errors, 'identifier', 'required')
      } else if (!this.emailRegex.test(this.loginForm['identifier'])) {
        this.$set(this.errors, 'identifierRegex', 'required')
      }
      if (!this.loginForm['password']) {
        this.$set(this.errors, 'password', 'required')
      }
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onLogin () {
      if (this.validateFields()) {
        this.$store.dispatch('auth/login', this.loginForm)
      }
    },
    registerAccount () {
      this.$router.push({name: 'Signup'})
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/login.sass'
@import '../../sass/modal.sass'
</style>
