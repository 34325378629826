import auth from "@/api/auth"
import products from "@/api/products"
import router from '@/router'

const state = {
  user: null,
  userType: null,
  isLogin: false,
  signatureImage: [],
  cartDetails:[],
  personalDetails: null,
  userDetails: null,
  creditLimit: {},
  salesRep: 'SalesRep'
}

const getters = {
  userId (state:any) {
    let id = null
    if (state.user && state.user.id) {
      id = state.user.id
    } else if (window.localStorage.getItem('userID')) {
      id = window.localStorage.getItem('userID')
    }
    return id
  }
}
const mutations = {
  setUser (state:any, payload:any) {
    state.user = payload
  },
  setUserType(state:any, payload:any) {
    state.userType = payload
  },
  loginPopUp (state:any, value:any) {
    state.isLogin = value
  },
  setSignature (state:any, value:any) {
    state.signatureImage = value
  },
  setCartDetails (state:any, value:any) {
    state.cartDetails = value
  },
  setPersonalDetails (state:any, value:any) {
    state.personalDetails = value
  },
  setUserDetail(state: any, value: any) {
    state.userDetails = value
  },
  setCreditLimit(state: any, value:any) {
    state.creditLimit = value
  }
}

const actions = {
  signUP ({ commit }: any, data: any) {
    commit('setLoader', true, {root: true})
    auth.signUP(data).then((response:any) => {
      commit('setMessage', { type: 'success', 'message': 'You have been successfully registered!'},  { root: true })
    })
  },
  login ({ commit, dispatch, state }:any, data:any) {
    commit('setLoader', true, {root: true})
    auth.login(data).then((response:any) => {
      if (response.status === 200) {
        window.localStorage.setItem('token', response.data.jwt)
        window.localStorage.setItem('userID', response.data.user.id)
        window.localStorage.setItem('userName', response.data.user.fullname)

        commit('setUser', response.data.user)
        commit('setUserType', response.data.user.UserType)
        dispatch('getUserDetails')
        dispatch('products/getCartList', 1, {root:true})

        commit('loginPopUp', false)
        if (response.data.user.UserType !== state.salesRep) {
          if (response.data.user.personal_detail && response.data.user.personal_detail.EIN_proof && response.data.user.personal_detail.EIN_proof.length > 0 && response.data.user.personal_detail.trade_info && response.data.user.personal_detail.trade_info.length > 0) {
            commit('setSignature', [response.data.user.personal_detail.signature])
            const route: any = router
            if (route.history && route.history.current && route.history.current.fullPath && route.history.current.fullPath != '/register') {
              router.push({ path: route.history.current.fullPath }).catch(() => {
                console.log('duplicate')
              })
            } else {
              router.push({ name: 'Home' }).catch(() => {
                console.log('duplicate')
              })
            }
          } else {
            router.push({ name: 'CreditForm' }).catch(() => {
              console.log('duplicate')
            })
          }
        }
      }
    })
  },
  getUserDetails ({ commit }: any) {
    commit('setLoader', true, {root: true})
    
    auth.getUserDetails().then((response: any) => {
      commit('setUserDetail', response.data)
      commit('setUserType', response.data.UserType)
    })
  },
  forgotPassword ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.forgotPassword(data).then((response: any) => {
      commit('setMessage', { type: 'success', message: "Please check your email", content: `We’ve sent an email to ${data.email} with instructions to reset your password.`},  { root: true })
    })
  },
  resetPassword ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.resetPassword(data).then((response: any) => {
      commit('setMessage', { type: 'success', message: "Your Password has been changed, You can login with new password!"},  { root: true })
      commit('loginPopUp', true)
    })
  },
  uploadFile ({ commit }: any, data:any) {
    auth.uploadFile(data).then((response: any) => {
      commit('setSignature', response.data)
    })
  },
  deleteFile ({ commit }: any, id:any) {
    auth.deleteFile(id).then((response: any) => {
      commit('setSignature', [])
    })
  },
  personalDetails ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.personalDetails(data.details).then((response: any) => {
      if (data.type === 3) {
        commit('setMessage', { type: 'success', message: 'Thank you!', content: "Your details have been updated"},  { root: true })
      }
    })
  },
  getPersonalDetails ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.getPersonalDetails(data.id).then((response: any) => {
      if (data.type === 3) {
        //commit('setMessage', { type: 'success', message: 'Thank you!', content: "Your details have been updated"},  { root: true })
        commit('setPersonalDetails',response.data)
      }
    })
  },
  updateDetails ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.updateDetails(data.details, data.id).then((response: any) => {
      if (data.type === 3) {
        commit('setMessage', { type: 'success', message: 'Thank you!', content: "Your details have been updated"},  { root: true })
      }
    })
  },
  profileUpdate ({ commit, dispatch }: any, data:any) {
    commit('setLoader', true, {root: true})
    auth.profileUpdate(data).then((response: any) => {
      if (response && response.data && response.data.user) {
        dispatch('getUserDetails')
        commit('setMessage', { type: 'success', method: 'success', message: 'Thank you!', content: "Your details have been updated!"},  { root: true })
      } else if (response && response.data && response.data.toLowerCase() == 'old password is wrong') {
        commit('products/setNotification', { type: 'error', method: 'error', message: response.data},  { root: true })
      }
    })
  },
  getCreditLimit ({ commit }: any) {
    auth.getCreditLimit().then((response: any) => {
      if (response.data && response.data.length > 0){
        commit('setCreditLimit', response.data[0])
      }
    })
  },
  updateCreditLimit ({ commit }: any, data: any) {
    auth.updateCreditLimit(data.id, data.details).then((response: any) => {
      if (response.data && response.data.length > 0){
        commit('setCreditLimit', response.data[0])
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
