import contact from "@/api/contact"

const state = {}

const getters = {}
const mutations = {}

const actions = {
  sendQuery ({ commit }: any, data: any) {
    commit('setLoader', true, {root: true})
    contact.sendQuery(data).then((response:any) => {
      commit('setMessage', { type: 'success', 'message': "Thanks! We appreciate that you've taken the time to write us. We'll get back to you"},  { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
