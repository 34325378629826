import salesrep from "@/api/salesrep"

const state = {
  salesAgent: {},
  customerList: {}
}

const mutations = {
  setSalesAgent (state:any, payload:any) {
    state.salesAgent = payload
  },
  setCustomerList (state:any, payload:any) {
    state.customerList = payload
  }
}

const actions = {
  fetchSalesRep ({ commit }: any, data: any) {
    commit('setLoader', true, {root: true})
    salesrep.fetchSalesRep(data).then((response:any) => {
      commit('setSalesAgent', response.data)
    })
  },
  fetchCustomerList ({ commit }: any, data: any) {
    commit('setLoader', true, {root: true})
    salesrep.fetchCustomerList(data).then((response:any) => {
      commit('setCustomerList', response.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
