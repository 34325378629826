<template>
  <div class="banner-wrapper center-wrapper">
    <div :class="{'isHome': isHome}" class="banner">
      <carousel :autoplayTimeout="5000" :autoplay="true" :loop="true" :per-page="1" :mouse-drag="true" :navigationEnabled="true">
        <slide @slideclick="handleSlideClick">
          <Overlay class="banner__overlay"/>
          <img class="banner" src="~@/assets/images/banner.png" />
        </slide>
        <slide @slideclick="handleSlideClick">
          <Overlay class="banner__overlay"/>
          <img class="banner" src="~@/assets/images/banner2.jpeg" />
        </slide>
        <slide @slideclick="handleSlideClick">
          <Overlay class="banner__overlay"/>
          <img class="banner" src="~@/assets/images/banner3.jpeg" />
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import Overlay from '@/components/organisms/Overlay'
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Carousel,
    Slide,
    Overlay
  },
  methods: {
    handleSlideClick() {
      this.$router.push({ name: 'Products' })
    }
  }
}
</script>
<style lang="sass" scoped>
.banner
  height: 50.9em
  background-size: cover
  background-repeat: no-repeat
  position: relative
  width: 100%
  margin-bottom: 6.2em
  cursor: pointer
  .banner__overlay
    position: absolute
    bottom: 178px
    @media screen and (max-width: $ms-breakpoint)
      bottom: 0
  @media(max-width: $ms-breakpoint)
    height: 100%
    @media( max-width: $md-breakpoint)
      flex-direction: column
      padding-top: 2rem
  &__text
    display: flex
    justify-content: center
    align-items: center
    color: $white
    font-size: 36px
    @media( max-width: $md-breakpoint)
      font-size: 28px
    @media( max-width: $phone-breakpoint)
      font-size: 24px
  &__collection-text
    color: $white
    font-size: 60px
    margin-left: 1rem
    @media( max-width: $md-breakpoint)
      font-size: 40px
    @media( max-width: $phone-breakpoint)
      font-size: 32px
.logo
  @media( max-width: $md-breakpoint)
    height: 6rem
</style>
<style lang="sass">
.VueCarousel-navigation-button, .VueCarousel-dot
  &:focus, &:active, &:visited
    outline: none !important
.banner
  &.isHome
    .VueCarousel-navigation-next
      right: -20px
    .VueCarousel-navigation-prev
      left: -20px
    .VueCarousel
      max-height: 100%
      .VueCarousel-wrapper
        .VueCarousel-slide
          position: relative
    @media(max-width: $ms-breakpoint)
      .VueCarousel-navigation-next, .VueCarousel-navigation-prev
        display: none
</style>
