import auth from "@/api/auth"
const state = {
    uploadfileData: null
}

const getters = {}
const mutations = {
  setFilePath (state: any, value:any) {
    state.uploadfileData = value
  }
}

const actions = {
  importFile ({commit}: any, data: any) {
    auth.importUser(data).then((response: any) => {
      commit('products/setNotification', { type: 'imported', method: 'success', message: 'File imported successfully!'}, {root: true})
    }).catch((error: any) => {
      if (error.response.data.message) {
        commit('products/setNotification', { type: 'imported', method: 'error', message: error.response.data.message}, {root: true})
      }
    })
  },
  uploadFile ({ commit }: any, data:any) {
    auth.uploadFile(data).then((response: any) => {
      commit('products/setNotification', { type: 'upload', method: 'success', message: 'File uploaded successfully!'}, {root: true})
      commit('setFilePath',{ path: response.data[0].url })
    }).catch((error: any) => {
      if (error.response.data.message) {
        commit('products/setNotification', { type: 'upload', method: 'error', message: error.response.data.message}, {root: true})
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
