import client from "./client"

class auth {
  fetchSalesRep (data: any) {
    return client.withAuth().post('/user-sales-reps/sales', null, data)
  }
  fetchCustomerList (id: any) {
    return client.withAuth().get(`/user-sales-reps/userList/${id}`)
  }
}

export default new auth()