import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
Vue.use(VueRouter)
const isAuthenticated = (to:any, from:any, next:any) => {
  if (window.localStorage.getItem('token')) {
    next()
    return
  }
  store.commit('auth/ ', true)
  store.commit('auth/loginPopUp', true)
  // next({ name: 'Home' })
}
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/register',
    name: 'Signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Signup.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    // beforeEnter: isAuthenticated
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    component: () => import('../views/ProductDetails.vue')
    // beforeEnter: isAuthenticated
  },
  {
    path: '/viewcart',
    name: 'Cart',
    component: () => import('../views/Cart.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/credit-form',
    name: 'CreditForm',
    component: () => import('../views/CreditApplication.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('../components/molecules/ResetPassword.vue')
  },
  {
    path: '*',
    component: () => import('../views/NotFoundPage.vue')
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: () => import('../views/Wishlist.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/order-history-details',
    name: 'SaleHistory',
    component: () => import('../views/SaleHistory.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/your-credit-limit',
    name: 'CreditLimit',
    component: () => import('../views/CreditLimit.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/addCSV',
    name: 'AddCsv',
    component: () => import('../views/AddCsv.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/your-sale-agent',
    name: 'SalesRep',
    component: () => import('../views/SalesRep.vue'),
    beforeEnter: isAuthenticated
  },
  {
    path: '/customer-list',
    name: 'CustomerList',
    component: () => import('../views/CustomerList.vue'),
    beforeEnter: isAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
