<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog">
        <div class="image-section">
          <img :src="require(`@/assets/icons/${getMessage.image}`)" >
        </div>
        <header :class="type" class="modal-header">
          <h4>
            {{ getMessage.header }}
          </h4>
        </header>
        <template v-if="getMessage.content">
          <div class="content">
            {{ getMessage.content }}
          </div>
        </template>
        <footer v-if="isReg && $route.name.toLowerCase() !== 'profile'" class="modal-footer">
          <button class="button button__login" @click="loginPopUp(true)">Login</button>
        </footer>
        <footer v-else-if="isResend && type === 'success'" class="modal-footer">
          <button class="button button__login" @click="onResend()">Resend</button>
        </footer>
        <footer v-else-if="type === 'alert'" class="modal-footer">
          <button class="button button__login" @click="$emit('onClick', 'confirm')">Yes</button>
        </footer>
        <div class="modal-close">
          <div v-if="type !== 'alert'" @click="closeMessage()">Close</div>
          <div v-else @click="$emit('onClick', 'no')">No</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    isReg: {
      type: Boolean,
      default: false
    },
    isResend: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getMessage () {
      const data = {
        image: 'unsuccessful.svg',
        header: this.message,
        content: this.content
      }
      if (this.type === 'success') {
        data.image = 'success-icon.svg'
      } else if (this.type === 'alert') {
        data.image = 'alert.png'
      }
      return data
    }
  },
  methods: {
    closeMessage () {
      if (this.$route.name === 'resetPassword') {
        if (this.type !== 'error') {
          this.$router.push('/')
        }
      } else if (this.$route.name === 'CreditForm') {
        if (this.type === 'error') {
          this.$store.commit('setMessage', null)
        } else {
          this.$router.push('/')
        }
      }
      this.$store.commit('setMessage', null)
    },
    onResend() {
      this.$store.commit('setMessage', null)
    },
    loginPopUp (value) {
      this.closeMessage()
      this.$store.commit('auth/loginPopUp', value)
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-fade-enter, .modal-fade-leave-active
  opacity: 0
.modal-fade-enter-active, .modal-fade-leave-active
  transition: opacity .5s ease

.modal-backdrop
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(252, 252, 252, 0.7)
  display: flex
  justify-content: center
  align-items: center
  z-index: 4

.modal
  background: $white
  max-width: 500px
  width: 90%
  padding: 30px 30px 35px 35px
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2)
  text-align: center
  border: 5px solid $orange
  border-radius: 14px
  .modal-header,.modal-footer
    display: flex
  .image-section
    margin-bottom: 10px
  .modal-header
    justify-content: center
    h4
      margin: 0
      line-height: 30px
      font-size: 22px
      font-weight: normal
      width: 70%
      @media screen and (max-width: $phone-breakpoint)
        line-height: 22px
        font-size: 1em

  .modal-body
    margin-top: 10px
    opacity: 0.5
    color: $text_grey
    font-size: 15px
    line-height: 23px
  .modal-footer
    justify-content: center
  .modal-close
    margin-top: 20px
    color: $orange
    font-size: 17px
    cursor: pointer
    font-weight: 600
  .button
    margin-top: 25px
    height: 45px
    width: 190px
    border-radius: 22.5px
    color: $white
    font-size: 17px
    letter-spacing: 0
    line-height: 23px
    cursor: pointer
    text-align: center
    border: none
    &__login
      background: $orange
</style>
