import orders from "@/api/order"
import router from '@/router'
const state = {
  orderList: [],
  cartInfo: []
}

const getters = {}
const mutations = {
  setOrderList (state: any, payload: any) {
    state.orderList = payload
  },
  setCartInfo (state: any, payload: any) {
    state.cartInfo = payload
  }
}

const actions = {
  fetchOrder ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    orders.fetchOrder(query).then((response:any) => {
      commit('setOrderList', response.data)
    }).catch(() => {
      commit('setOrderList', [])
    })
  },
  
  addOrder ({commit,dispatch}:any, data: any) {
    orders.addOrder(data).then((response:any) => {
        commit('setNotification', { type: 'Order-list', method: 'success', message: 'Added to your Order list!'})
        commit('setOrderList', 1 )
      })  
  },
  getCartInfo ({ commit }: any, data:any) {
    commit('setLoader', true, {root: true})
    orders.getCartInfo(data.id).then((response: any) => {
      commit('setCartInfo',response.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
