import products from "@/api/products"
import router from '@/router'
const state = {
  productList: [],
  categoriesList: [],
  subCategoriesList: [],
  collectionList: [],
  relatedProducts: [],
  wareHousesList: [],
  productsDetails: {},
  collectionProducts: [],
  cartItems: [],
  wishList: [],
  notification: null,
  cartItemCount: 0,
  featuredProducts: []
}

const getters = {}
const mutations = {
  setProductList (state: any, payload: any) {
    state.productList = payload
  },
  setCategories (state: any, payload:any) {
    state.categoriesList = payload
  },
  setSubCategories (state: any, payload:any) {
    state.subCategoriesList = payload
  },
  setCollections (state: any, payload:any) {
    state.collectionList = payload
  },
  setWareHouses (state: any, payload:any) {
    state.wareHousesList = payload
  },
  setProductDetails (state: any, payload:any) {
    state.productsDetails = payload
  },
  setCollectionProduct (state: any, payload:any) {
    state.collectionProducts = payload
  },
  setRelatedProduct (state: any, payload:any) {
    state.relatedProducts = payload
  },
  setWishList (state: any, payload:any) {
    state.wishList = payload
  },
  setNotification (state: any, payload:any) {
    state.notification = payload
  },
  setCartList (state: any, payload: any) {
    state.cartItems = payload
  },
  setCartItemCount (state: any, payload: any) {
    state.cartItemCount = payload
  },
  setFeaturedProducts (state: any, payload: any) {
    state.featuredProducts = payload
  }
}

const actions = {
  fetchProducts ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchProducts(query).then((response:any) => {
      commit('setProductList', response.data)
    }).catch(() => {
      commit('setProductList', [])
    })
  },
  fetchFeaturedProducts({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchProducts(query).then((response:any) => {
      commit('setFeaturedProducts', response.data)
    }).catch(() => {
      commit('setFeaturedProducts', [])
    })
  },
  fetchCategories ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchCategories(query).then((response:any) => {
      commit('setCategories', response.data)
    }).catch(() => {
      commit('setCategories', [])
    })
  },
  fetchSubCategories ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchSubCategories(query).then((response:any) => {
      commit('setSubCategories', response.data)
    }).catch(() => {
      commit('setSubCategories', [])
    })
  },
  fetchCollections ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchCollections(query).then((response:any) => {
      commit('setCollections', response.data)
    }).catch(() => {
      commit('setCollections', [])
    })
  },
  fetchWareHouses ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchWareHouses(query).then((response:any) => {
      commit('setWareHouses', response.data)
    }).catch(() => {
      commit('setWareHouses', [])
    })
  },
  getProductDetails ({ commit }: any, id: any) {
    commit('setLoader', true, {root: true})
    products.fetchProductDetails(id).then((response:any) => {
      commit('setProductDetails', response.data)
      commit('setWishList', response.data.wishlists)
    }).catch(() => {
       commit('setProductDetails', [])
    })
  },
  fetchCollectionProducts ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchProducts(query).then((response:any) => {
      commit('setCollectionProduct', response.data)
    }).catch(() => {
       commit('setCollectionProduct', [])
    })
  },
  fetchRelatedProducts ({ commit }: any, query: any) {
    commit('setLoader', true, {root: true})
    products.fetchProducts(query).then((response:any) => {
      commit('setRelatedProduct', response.data)
    })
  },
  addWishList ({commit}:any, data: any) {
    products.addWishList(data).then((response:any) => {
      commit('setNotification', { type: 'wishlist', method: 'success', message: 'Added to your wish list'})
      commit('setWishList', [{ id: response.data.id, product: response.data.product.id, users_permissions_user: response.data.users_permissions_user.id }])
    })
  },
  removeWishList ({commit}:any, id: any) {
    products.removeWishList(id).then((response:any) => {
      commit('setNotification', { type: 'wishlist', method: 'warn', message: 'Removed from your wish list'})
      commit('setWishList', [])
    })
  },
  addToCart ({commit,dispatch}:any, data: any) {
    products.userCartListExists(1).then((response:any) => {
      const result= response.data;
      
      if (result.length === 0) {
        products.addToCart(data).then((response:any) => {
          commit('setNotification', { type: 'Cart-list', method: 'success', message: 'Added to your cart list!'})
          commit('setCartItemCount', 1 )
        })  
      } else {
        const cartIdDetails= result[0].id
        products.updateToCart(data).then((response:any) => {
          if(response.data != null){
            products.update(response.data, cartIdDetails).then((response:any) =>{
              const totalRecords= response.data
              commit('setNotification', { type: 'Cart-list', method: 'success', message: 'Added to your cart list!'})
              commit('setCartItemCount',  totalRecords.cart_details[0].CartProduct.length ? totalRecords.cart_details[0].CartProduct.length : 0 )
            })
          }
          else{
            commit('setNotification', { type: 'Cart-list', method: 'success', message: 'Added to your cart list!'})
            commit('setCartItemCount',  response.data.cart_details[0].CartProduct.length ? response.data.cart_details[0].CartProduct.length : 0 )
          }
        })
      }
    })
    dispatch("getCartList", 1);
  },
  removeCart ({commit}:any, id: any) {
    products.removeCart(id).then((response:any) => {
      commit('setNotification', { type: 'Cart-list', method: 'warn', message: 'Removed from your cart list!'})
    })
  },
  updateToCart ({commit,dispatch}:any, data: any) {
    commit('setLoader', true, {root: true})
    products.updateToCart(data).then((response:any) => {
      products.update(response.data, 14).then((response:any) =>{
        const totalRecords =response.data
        commit('setNotification', { type: 'Cart-list', method: 'success', message: 'Added to your cart list!'})
        commit('setCartItemCount',  totalRecords.cart_details[0].CartProduct.length ? totalRecords.cart_details[0].CartProduct.length : 0 )
      })
    })
    dispatch("getCartList", 1);
  },
  updateCart({commit}:any, data: any){
    commit('setLoader', true, {root: true})
    products.update(data, data.id).then((response:any) =>{
      commit('setNotification', { type: 'Cart-list', method: 'success', message: 'Cart updated successfully!'})
      const totalRecords =response.data
      commit('setCartItemCount',  totalRecords.cart_details[0].CartProduct.length ? totalRecords.cart_details[0].CartProduct.length : 0 )
    })
  },
  getCartList ({commit}:any, id: any) {
    // commit('setLoader', true, {root: true})
    products.userCartListExists(1).then((response:any) => {
      const result= response.data;
      if (result && result.length > 0) {
        products.getCartList(result[0].id).then((responsein:any) => {
          commit('setCartList', responsein.data)
          commit('setCartItemCount', result[0].cart_details[0].CartProduct.length ? result[0].cart_details[0].CartProduct.length: 0 )
          window.localStorage.setItem('totalItems', result[0].cart_details[0].CartProduct.length ? result[0].cart_details[0].CartProduct.length: 0)
        }).catch(() => {
           commit('setCartList', [])
        })
      }
    });
  },
  getCartItemcounter ({commit}:any, count: any) {
    commit('setCartItemCount', count)
    window.localStorage.setItem('totalItems', count)
  },
  sendOrderEmail({commit}:any, data: any) {
    commit('setLoader', true, {root: true})
    
    products.sendEmail(data).then((response:any) =>{
      commit('setNotification', { type: 'cart', method: 'success', message: 'Order detail has sent on you email address!'})
    }).catch(() => {
      console.log('error')
   })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
