import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueMeta from 'vue-meta'
import Notifications from 'vue-notification'
Vue.use(VueMeta)
Vue.use(Notifications)
Vue.config.productionTip = false
// axios.interceptors.request.use(
//   function(request) {
//     console.log('request', request)
//     if (request.url !== '/api/wishlists') {
//       store.commit('setLoader', true)
//     }
//     return request
//   }
// )
axios.interceptors.response.use(
  function(response) {
    store.commit('setLoader', false)
    return response
  },
  function(error) {
    store.commit('setLoader', false)
    store.commit('setErrorStatus', error.response.status)
    if (error.response.status === 401) {
      window.localStorage.clear()
    } else if (error.response.status === 400) {
      if (error.response.data.message) {
        store.commit('products/setNotification', { method: 'error', message: error.response.data.message[0].messages[0].message})
      }
    } else {
      store.commit('products/setNotification', { method: 'error', message: 'Something went wrong'})
    }
    return Promise.reject(error)
  }
);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
