import client from "./client"

class auth {
  signUP (data: any) {
    return client.withoutAuth().post("/auth/local/register", null, data)
  }
  login(data: any) {
    return client.withoutAuth().post("/auth/local", null, data)
  }
  getUserDetails () {
    return client.withAuth().get("/users/me")
  }
  forgotPassword (data: any) {
    return client.withoutAuth().post('/auth/forgot-password', null, data)
  }
  resetPassword (data:any) {
    return client.withoutAuth().post('/auth/reset-password', null, data)
  }
  personalDetails (data:any) {
    return client.withAuth().post('/personal-details', null, data)
  }
  getPersonalDetails (id:any) {
    return client.withAuth().post(`/personal-details/${id}`)
  }
  updateDetails (data:any, id:any) {
    return client.withAuth().put(`/personal-details/${id}`, null, data)
  }
  profileUpdate(data: any) {
    return client.withAuth().post("/personal-details/update", null, data)
  }
  uploadFile (data:any) {
    return client.withAuth().post('/upload', null, data)
  }
  deleteFile (id: any) {
    return client.withAuth().delete(`/upload/files/${id}`)
  }
  getCreditLimit () {
    return client.withAuth().get('/user-credit-limits')
  }
  updateCreditLimit (id: any, data: any) {
    return client.withAuth().put(`/user-credit-limits/${id}`, null, data)
  }
  importUser (data: any) {
    return client.withAuth().post('/user-credit-limits/import', null, data)
  }
}

export default new auth()