import client from "./client"

class order {
  fetchOrder (query: any) {
    return client.withAuth().get("/orders", query)
  }
  addOrder (data: any) {
    return client.withAuth().post('/orders', null, data)
  }
  getCartInfo (id:any) {
    return client.withAuth().get(`/personal-details/${id}`)
  }
}

export default new order()