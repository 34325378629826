export default {
  methods: {
    getMediaURL(media, format) {
      const PATH = process.env.VUE_APP_API
      if (media && media.url) {
        if (media.formats && media.formats[format]) {
          if (media.formats[format].url) {
            return `${PATH}${media.formats[format].url}`
          } else {
            return `${PATH}${media.url}`
          }
        } else {
          return `${PATH}${media.url}`
        }
      }
      // If no URL return undefined (prevent errors)
      return undefined
    },
    getGalleryItems(images) {
      const galleryImages = []
      images.forEach((item) => {
        const galleryImage = {}
        if (item) {
          galleryImage.src = this.getMediaURL(item)
          galleryImage.head_image = this.getMediaURL(item, 'large')
          galleryImage.thumbnail = this.getMediaURL(item.media, 'small')
          galleryImage.w = item.width
          galleryImage.h = item.height
          galleryImage.title = item.caption
        }
        galleryImages.push(galleryImage)
      })
      return galleryImages
    }
  }
}
