<template>
  <div id="app">
    <Header @login="loginPopUp(true)"></Header>
      <router-view />
    <Footer></Footer>
    <Login v-if="isLogin" @close="loginPopUp(false)"/>
    <notifications group="notification" position="center bottom" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/organisms/Footer.vue'
import Login from '@/components/molecules/Login'
export default {
  components: {
    Header,
    Footer,
    Login
  },
  computed: {
    ...mapState('auth', ['isLogin', 'userDetails']),
    ...mapState('products', ['notification']),
  },
  watch: {
    notification: function () {
      if (this.notification && this.notification.method && this.notification.message) {
        this.$notify({
          group: 'notification',
          type: this.notification.method,
          title: this.notification.message
        })
      }
    },
    userDetails: function () {
      this.getAPI()
    }
  },
  mounted() {
    this.getAPI()
  },
  methods: {
    getAPI() {
      if (this.userDetails && this.userDetails.id) {
        this.$store.dispatch('products/getCartList', 0)
        this.$store.dispatch('auth/getCreditLimit') // parseInt(this.userDetails.id)
      }
    },
    loginPopUp (value) {
      this.$store.commit('auth/loginPopUp', value)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
